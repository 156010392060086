import {
  ThemeProvider,
  CssBaseline,
  Stack,
} from '@mui/material';
import './index.css';
import { Divider } from '../components/divider';
import { motion } from 'framer-motion';
import { FAQ } from '../sections/faq';
import { HeroSplash } from '../sections/hero-splash';
import { NavBar } from '../components/nav-bar';
import introVideo from '../assets/landing-video.mp4';
import introPoster from '../assets/qb-cover.png';
import { useEffect, useRef } from 'react';
import { TabletopGameEngine } from '../sections/tabletop-game-engine';
import { Dice } from '../sections/dice';
import { Testimonials } from '../sections/testimonials';
import { PlayerControl } from '../sections/player-control';
import { MissionStatement } from '../sections/mission-statement';
import { Logic } from '../sections/logic';
import { Prototype } from '../sections/prototype';
import { useDeviceSize } from '../use-device-size';
import { compassDarkTheme } from '../theme';

const IndexPage = () => {
  const { mobile } = useDeviceSize();

  const videoRef = useRef<HTMLVideoElement>(null);
  const delay = mobile ? 0 : 0.5;

  useEffect(() => {
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, delay * 1000);
  }, []);

  return (
    <>
        <ThemeProvider theme={compassDarkTheme}>
          <CssBaseline />
          <NavBar />
          <main
            style={{
              overflowX: 'hidden',
              margin: 'auto',
              padding: '16px',
              paddingTop: '60px',
              backgroundColor: '#2a2a2a',
              maxWidth: '1450px',
            }}>
            <HeroSplash />

            <motion.section
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay }}>
              <video
                ref={videoRef}
                src={introVideo}
                style={{ width: '100%', minHeight: 200 }}
                preload='auto'
                controls={mobile}
                loop
                muted
                playsInline
                poster={introPoster}
              />
            </motion.section>

            {/* <Kickstarter /> */}

            <MissionStatement />

            <Testimonials />

            {/* <GetMore /> */}

            <TabletopGameEngine />

            <Prototype />

            <Logic />

            <PlayerControl />

            <Dice />

            {/* <Price /> */}

            <Divider />

            <FAQ />

            <Divider />

            <Stack
              width={mobile ? '100%' : '80%'}
              alignItems='center'
              justifyContent='center'
              direction={mobile ? 'column' : 'row'}
              sx={{ margin: 'auto', mb: 8, mt: 8 }}>
              <img
                alt='Quest Bound'
                src='/qb-banner.svg'
                width='50%'
                style={{ maxWidth: 750 }}
              />
            </Stack>

            <Divider />
          </main>
        </ThemeProvider>
    </>
  );
};

export default IndexPage;

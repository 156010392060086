import { Typography, Stack, Link } from '@mui/material';
import { Divider } from '../components/divider';
import { motion } from 'framer-motion';

const operations: string[] = [
  'Primitives',
  'Variables',
  'Math',
  'Comparisons',
  'Boolean Alegra',
  'Random Numbers',
  'Conditions',
  'Functions',
  'Dictionaries',
  'Events',
];

export const Logic = () => {
  return (
    <Stack
      spacing={4}
      padding={4}
      mt={4}
      mb={4}
      alignItems='center'
      justifyContent='center'
      width='100%'>
      <Divider />

      <Stack width='100%' spacing={8} alignItems='center'>
        <Typography
          color='secondary'
          variant='h4'
          sx={{
            maxWidth: 600,
            fontFamily: 'CygnitoMonoPro-BoldR',
            textAlign: 'center',
          }}>
          Visually program your game's mechanics
        </Typography>

        <Stack direction='row' flexWrap='wrap' gap={4} justifyContent='center'>
          {operations.map((op, i) => (
            <motion.div key={i}>
              <Stack direction='row'>
                <Typography
                  sx={{
                    maxWidth: 600,
                    fontSize: '1.3rem',
                    textAlign: 'center',
                  }}>
                  {op}
                </Typography>
              </Stack>
            </motion.div>
          ))}
        </Stack>

        <Stack spacing={2} alignItems='center'>
          <Typography
            sx={{
              maxWidth: 600,
              fontFamily: 'CygnitoMonoPro-BoldR',
              textAlign: 'center',
              fontSize: '1.5rem',
            }}>
            No special syntax. No coding.
          </Typography>

          <Link
            href='https://docs.questbound.com/docs/logic/'
            target='_blank'
            sx={{ color: 'common.white', cursor: 'pointer' }}>
            Learn More
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

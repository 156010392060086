import React from 'react';
import { Stack, Typography } from '@mui/material';

type Feature = {
  title: string;
  description: React.ReactNode;
  status?: string;
};

export const features: Feature[] = [
  {
    title: 'What is Quest Bound?',
    description: (
      <Stack spacing={2}>
        <Typography>
          Quest Bound is a free and open source tabletop roleplaying game engine. It helps users
          create and play custom RPG rulesets by providing intuitive interfaces to create and
          automate the common entities of RPGs.
        </Typography>
        <Typography>
          You can think of it like a website builder like Wix, or a game engine like Unreal Engine.
          Instead of making websites and video games, you'll make robust, digital versions of pen &
          paper RPGs.
        </Typography>
      </Stack>
    ),
  },
  {
    title: 'Is Quest Bound a Virtual Tabletop?',
    description: (
      <Stack spacing={2}>
        <Typography>
          No. While virtual tabletops (VTTs) focus on the gameplay experience, Quest Bound focuses
          on creating custom TTRPG content.
        </Typography>
        <Typography>
          You can use Quest Bound by itself to run or play your campaigns at the table by using
          character sheets and supporting documents within the app.
        </Typography>
      </Stack>
    ),
  },
  {
    title: 'Does it work on mobile?',
    description: (
      <Stack spacing={2}>
        <Typography>
          Yes. As a web application, Quest Bound is accessible on any device with a web browser.
          Certain features, like the logic editor, will be better used on desktop.
        </Typography>
      </Stack>
    ),
  },
  {
    title: 'What systems does Quest Bound support?',
    description: (
      <Stack spacing={2}>
        <Typography>
          As an engine, Quest Bound is completely system agnostic. You won't find any tools built
          specifically for D20, Powered by the Apocalypse, or any other system.
        </Typography>
        <Typography>
          Instead, Quest Bound's logic system provides the fundamentals of computer programming
          through a visual editor, making it incredibly flexible in the systems it can adopt.
        </Typography>
      </Stack>
    ),
  },
  {
    title: "What programming operations are supported in Quest Bound's logic system?",
    description: (
      <Stack spacing={2}>
        <Typography>
          Quest Bound has a custom-built, node-based logic system that provides the following
          programming fundamentals:
        </Typography>
        <ul>
          <li>Primitive data types (number, Typography, boolean)</li>
          <li>Variables (global and local)</li>
          <li>Mathematical operations</li>
          <li>Comparison operations</li>
          <li>Boolean alegebra (AND, OR and NOT gates)</li>
          <li>Random number generation (dice rolls)</li>
          <li>Conditions (IF, ELSE and nested conditions)</li>
          <li>Maps (charts)</li>
          <li>Functions (actions)</li>
          <li>Class-like instantiation (items)</li>
          <li>Events (attribute dependencies)</li>
        </ul>
      </Stack>
    ),
  },
  {
    title: 'Does Quest Bound use AI?',
    description: (
      <Stack spacing={2}>
        <Typography>
          No. Quest Bound makes no use of AI. The platform uses a classical programming approach and
          relies on a custom-built logic framework to power the automation of rulesets.
        </Typography>
        <Typography>
          Whether users include AI generated content in their rulesets is up to them. Doing so does
          not violate the terms of service.
        </Typography>
        <Typography>
          Quest Bound has used AI in the past for promotional content, and previously integrated
          with OpenAI's Chat-GPT API for a feature that has since been removed. While future
          promotional material may use AI images, there are no plans to incorporate AI into the
          platform itself at this time.
        </Typography>
      </Stack>
    ),
  },
  {
    title: 'Do my players need to have a copy of Quest Bound?',
    description: (
      <Stack spacing={2}>
        <Typography>
          No. Players can connect to your content through a web browser while you have Quest Bound
          running.
        </Typography>
      </Stack>
    ),
  },
  {
    title: "Is Quest Bound useful if I'm not a TTRPG creator?",
    description: (
      <Stack spacing={2}>
        <Typography>
          You don't need to make completely original games to use Quest Bound. If you enjoy creating
          custom content, designing your own character sheets or managing your characters in one
          place, Quest Bound is for you.
        </Typography>
      </Stack>
    ),
  },
];

import { Typography, Stack } from '@mui/material';
import { Divider } from '../components/divider';
import { motion } from 'framer-motion';
import { useDeviceSize } from '../use-device-size';

export const Prototype = () => {
  const { mobile } = useDeviceSize();
  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Stack spacing={4} padding={4} mt={4} mb={4} alignItems='center'>
        <Divider />

        <Stack
          direction={mobile ? 'column' : 'row'}
          width='100%'
          justifyContent='space-between'
          gap={4}>
          <motion.div
            initial={{ transform: 'translateX(-200px)', display: 'flex' }}
            whileInView={{ transform: 'translateX(0px)' }}
            transition={{ duration: 0.5 }}>
            <Typography
              sx={{
                width: 600,
                maxWidth: mobile ? '90dvw' : '50dvw',
                fontFamily: 'CygnitoMonoPro-BoldR',
              }}
              variant='h3'>
              Rapidly prototype and play test your next game
            </Typography>
          </motion.div>
          <Stack spacing={4}>
            <Typography
              sx={{
                maxWidth: 600,
                fontSize: '1.3rem',
              }}>
              Quest Bound can bootstrap your rulebook from PDF and CSV files in seconds. Collaborate
              with your team to draft the first version of your game, lay out character sheets with
              automated mechanics, then add playtesters to gather valuable feedback.
            </Typography>

            <Typography
              sx={{
                maxWidth: 600,
                fontSize: '1.3rem',
              }}>
              <span style={{ color: '#E66A3C' }}>
                Iterate on your game and publish updates in real time.{' '}
              </span>
              Control playtester access, publish and distribute your game all on one platform.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </motion.div>
  );
};

import { Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Divider } from '../components/divider';
import { ReactNode } from 'react';

interface InfoSectionProps {
  title: string;
  children: ReactNode;
}

export const InfoSection = ({ title, children }: InfoSectionProps) => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}>
      <Divider />
      <Stack spacing={6} sx={{ mt: 6, mb: 6, pl: 1, pr: 1 }} alignItems='center'>
        <Typography
          variant='h2'
          id={title.toLowerCase()}
          sx={{
            fontSize: '2.5rem !important',
            fontFamily: 'CygnitoMonoPro-BoldR',
            textAlign: 'center',
          }}>
          {title}
        </Typography>

        <Stack
          direction='row'
          sx={{ gap: 10, flexWrap: 'wrap', margin: 'auto' }}
          justifyContent='center'>
          {children}
        </Stack>
      </Stack>
    </motion.section>
  );
};

import { ExpandMore } from '@mui/icons-material';
import {
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { features } from './features';
import { useDeviceSize } from '../use-device-size';

export const FAQ = () => {
  const { mobile } = useDeviceSize();

  return (
    <Stack
      sx={{ margin: 'auto', mt: 6, mb: 6 }}
      padding={2}
      spacing={2}
      maxWidth={820}
      width='100%'>
      {features.map((f) => (
        <Accordion key={f.title}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack direction={'row'} alignItems='center' spacing={2}>
              <Typography variant='h5' maxWidth={!!f.status && mobile ? '60%' : '100%'}>
                {f.title}
              </Typography>
              {f.status && (
                <Typography sx={{ fontStyle: 'italic', color: 'water.main' }}>{f.status}</Typography>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>{f.description}</AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};

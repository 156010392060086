import { Stack,  Link, Typography } from '@mui/material';
import { useDeviceSize } from '../use-device-size';
import { LogoIcon } from '../logo-icon';

export const NavBar = () => {
  const { mobile } = useDeviceSize();

  return (
    <Stack
      sx={{
        position: 'fixed',
        zIndex: 1000,
        width: '95dvw',
        backgroundColor: 'rgba(42,42,42,0.8)',
      }}
      alignItems='center'>
      <Stack
        sx={{ position: 'relative', maxWidth: 1200 }}
        height={60}
        width='100vw'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        padding={1}
        pl={2}
        pr={2}>
        <Stack direction='row' spacing={2} alignItems='center'>
          <LogoIcon />
          {!mobile && <Typography sx={{ fontFamily: 'CygnitoMonoPro-BoldR' }}>Quest Bound</Typography>}
        </Stack>
        <Stack direction='row' spacing={4}>
          <Link
            href='https://github.com/curtmorgan3/quest-bound'
            target='_blank'
            sx={{ textDecoration: 'none', color: 'common.white', '&:hover': { color: 'grey' } }}>
            GitHub
          </Link>
          <Link
            href='https://discord.gg/7QGV4muT39'
            target='_blank'
            sx={{ textDecoration: 'none', color: 'common.white', '&:hover': { color: 'grey' } }}>
            Discord
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

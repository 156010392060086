import { Typography, Stack } from '@mui/material';
import { Divider } from '../components/divider';

export const MissionStatement = () => {
  return (
    <Stack
      spacing={4}
      padding={4}
      mt={4}
      mb={4}
      alignItems='center'
      justifyContent='center'
      width='100%'>
      <Divider />

      <Stack width='100%' spacing={2} alignItems='center'>
        <Typography
          color='secondary'
          variant='h4'
          sx={{
            maxWidth: 600,
            fontFamily: 'CygnitoMonoPro-BoldR',
            textAlign: 'center',
          }}>
          Quest Bound's Mission
        </Typography>
        <Typography
          sx={{
            maxWidth: 600,
            fontSize: '1.3rem',
            textAlign: 'center',
          }}>
          To empower tabletop gamers and creators to build robust, digital versions of their games
        </Typography>
      </Stack>
    </Stack>
  );
};

import { Grid, Link, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useDeviceSize } from '../use-device-size';

export const HeroSplash = () => {
  const { mobile } = useDeviceSize();
  return (
    <Grid xs={12} lg={7} sx={{ mb: 3 }}>
      <Stack height='100%' width='100%' padding={1} paddingTop={mobile ? 3 : 8} alignItems='center'>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          <Stack spacing={2} alignItems='center'>
            <Typography
              variant='h2'
              sx={{
                fontFamily: 'CygnitoMonoPro-BoldR',
                textAlign: 'center',
              }}>
              Make Your Own Rules with
            </Typography>
            <Typography
              variant='h2'
              color='secondary'
              sx={{
                fontFamily: 'CygnitoMonoPro-BoldR',
                textAlign: 'center',
              }}>
              Quest Bound
            </Typography>

            <Typography
              sx={{
                fontSize: '1.2rem',
                maxWidth: 500,
                textAlign: 'center',
              }}>
              Free and open source software to elevate the digital version of your tabletop game
              using drag and drop editors and visual programming
            </Typography>
          </Stack>
        </motion.div>

        <Link
          href='https://github.com/curtmorgan3/quest-bound'
          target='_blank'
          sx={{
            color: 'common.white',
            '&:hover': { color: 'grey' },
            fontSize: '1.5rem',
            maxWidth: 500,
            textAlign: 'center',
            mt: 8,
          }}>
          Download Quest Bound's Source Code Here
        </Link>
      </Stack>
    </Grid>
  );
};

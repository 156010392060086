import { useEffect, useRef } from 'react';
import { useDeviceSize } from '../use-device-size';

export const VideoPlayer = ({
  src,
  poster,
  autoplay = false,
}: {
  src: string;
  poster?: string;
  autoplay?: boolean;
}) => {
  const { mobile } = useDeviceSize();
  const videoRef = useRef<HTMLVideoElement>(null);
  const delay = mobile ? 0 : 4;

  useEffect(() => {
    setTimeout(() => {
      if (videoRef.current && autoplay) {
        videoRef.current.play();
      }
    }, delay * 1000);
  }, []);

  return (
    <video
      playsInline
      poster={poster}
      ref={videoRef}
      controls={!autoplay}
      src={src}
      style={{
        width: '100%',
        height: 660,
        minWidth: '300px',
        outline: '2px solid',
        outlineColor: '#E66A3C',
        outlineOffset: '5px',
      }}
      preload='auto'
      muted
      loop
    />
  );
};

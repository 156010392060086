import { Avatar, Paper, Stack, Typography } from '@mui/material';
import { Divider } from '../components/divider';
import biz from '../assets/biz.png';
import kazelob from '../assets/kazelob.png';
import theNessey from '../assets/nessey.png';
import crow from '../assets/crow-brain-games.png';
import { motion } from 'framer-motion';

const testimonails = [
  {
    title: 'Crow Brain Games',
    img: crow,
    description:
      "Quest Bound is an absolute game-changer for automating TTRPG rulesets. Its intuitive, highly modular, and brilliantly expandable design, combined with the developer's outstanding support, rapid updates, and responsiveness, make this Tabletop Game Engine an indispensable tool for game creators, DMs, and gamers alike. Truly a genius innovation in the TTRPG space, I'm genuinely excited to see its future unfold!",
  },

  {
    title: 'Kazelob',
    img: kazelob,
    description:
      'Quest Bound has empowered me with the tools to craft personalized and automated character sheets for several tabletop role-playing games that currently only exist in print. I wholeheartedly endorse this app for anyone seeking to elevate their gaming experience into something truly exceptional.',
  },
  {
    title: 'Biz',
    img: biz,
    description:
      "Quest Bound has a powerful logic system that lets you customize every aspect of your game. I've been a fan of Quest Bound since it started, and it has become a must-have tool for any game creator or DM. I'm excited to see what the future holds for Quest Bound.",
  },
  {
    title: 'ThE_nEsSeY',
    img: theNessey,
    description:
      'Quest Bound is such an amazing tool! The character sheet builder allows for endless customization options. And with Quest Bound’s logic system, I can automate the time-consuming act of leveling up. Quest Bound is going to make TTRPGs more accessible for both DMs and players alike. I’m here for it.',
  },
  // {
  //   title: 'Artemys',
  //   img: artemys,
  //   description:
  //     "I found Quest Bound through Reddit while searching for a PC app to make custom character sheets. I entered the alpha and it has been progressing nicely since I've joined. The developer also takes a lot of feedback and comments into account and improves on the app constantly. So looking forward to see how it ends up!",
  // },
];

interface TestimonialProps {
  title: string;
  img: string;
  description: string;
  reverse?: boolean;
  index: number;
}

const Testimonial = ({ title, img, description, index }: TestimonialProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, transform: `translateY(${100 * index}px)` }}
      whileInView={{ opacity: 1, transform: 'translateY(0px)' }}>
      <Paper sx={{ padding: 2 }}>
        <Stack
          gap={2}
          sx={{ width: 300, height: 400, maxWidth: '90vw' }}
          padding={2}
          justifyContent='space-between'>
          <Stack spacing={1}>
            <Avatar src={img} sx={{ height: 60, width: 60 }} />
            <Typography>{`"${description}"`}</Typography>
          </Stack>

          <Typography fontSize={'0.9rem'} fontStyle='italic'>
            {title}
          </Typography>
        </Stack>
      </Paper>
    </motion.div>
  );
};

export const Testimonials = () => {
  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Divider />
      <Typography
        sx={{
          maxWidth: 800,
          fontFamily: 'CygnitoMonoPro-BoldR',
          textAlign: 'center',
          margin: 'auto',
          marginTop: 8,
          marginBottom: 8,
        }}
        variant='h4'>
        A tabletop game engine for publishers, game masters and players
      </Typography>
      <Stack
        gap={2}
        mt={2}
        width='100%'
        justifyContent='center'
        alignItems='center'
        direction='row'
        flexWrap='wrap'>
        {testimonails.map((t, i) => (
          <Testimonial key={t.title} index={i + 1} {...t} reverse={i % 2 === 0} />
        ))}
      </Stack>
    </motion.div>
  );
};
